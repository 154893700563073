/*
html5doctor.com Reset Stylesheet
v1.6.1
Last Updated: 2010-09-17
Author: Richard Clark - http://richclarkdesign.com
Twitter: @rich_clark
*/

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

html, body, div, span, iframe,
h1, h2, h3, h4, h5, h6, p,
b, i,
dl, dt, dd, ol, ul, li,
form, label,
footer, header, nav,
img, video,
input, select, textarea, button {
  margin:0;
  padding:0;
  border:0;
  outline:0;
  font-size:100%;
  vertical-align:baseline;
  background:transparent;
}

body {
  line-height:1;
}

article,aside,details,figcaption,figure,
footer,header,hgroup,menu,nav,section {
  display:block;
}

nav ul {
  list-style:none;
}

blockquote, q {
  quotes:none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content:'';
  content:none;
}

a {
  margin:0;
  padding:0;
  font-size:100%;
  vertical-align:baseline;
  background:transparent;
  color:inherit;
  -webkit-tap-highlight-color:rgba(0,0,0,0);
  text-decoration: none;
  cursor:pointer;
}

button, input, select, textarea {
  font-family : inherit;
  font-size : 100%;
}

input, select, textarea, button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  resize: none;
  vertical-align:middle;
  border: none;
  outline: none;
}

button {
  padding: 0;
  background: none;
  color: inherit;
  appearance: none;
  -webkit-tap-highlight-color:rgba(0,0,0,0);
  cursor: pointer;
  &:focus {
    outline: none;
  }
}
